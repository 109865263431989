import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('./starter-home/starter-home.module').then(
				(m) => m.StarterHomeModule
			),
	}
	// { path: '', component: LandingpageComponent },
	// { path: '404', },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
