import { Routes } from '@angular/router';
const ɵ0 = () => import("./starter-home/starter-home.module.ngfactory").then((m) => m.StarterHomeModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0,
    }
    // { path: '', component: LandingpageComponent },
    // { path: '404', },
];
export class AppRoutingModule {
}
export { ɵ0 };
