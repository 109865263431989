import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment.dev';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
// Uncomment the following lines to activate the service worker
// if (navigator.serviceWorker) {
// 	navigator.serviceWorker.register('sw.js').then(() => {
// 		console.log('Service worker installed')
// 	}, err => {
// 		console.error('Service worker error:', err);
// 	});
// }
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
