// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	apiUrl: 'https://storedev-api.primetime.company/',
	stripeKey: 'pk_test_xsqfNY2SQsVqY2Oaf6SHyGdy',
	// apiUrl : 'https://storev3-api.primetime.company/'
};

// export const environment = {
// 	production: false,
// 	apiUrl: 'https://storev2-api.primetime.company/',
// 	stripeKey: 'pk_test_xsqfNY2SQsVqY2Oaf6SHyGdy'
// };
